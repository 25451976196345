import React from 'react'
import Meta from '../components/Meta'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Header from '../components/Header'
import Footer from '../components/Footer'
import RichText from '../components/RichText'

class IndexRoute extends React.Component {
    render() {
        const routeData = this.props.data
        const metaDataElements = routeData.kontentItemSiteMetadata.elements
        const title = metaDataElements.title.value
        const subtitle = metaDataElements.subtitle.value

        const homepageElements = routeData.kontentItemHomepage.elements

        const aboutMeText = homepageElements.about_me

        return (
            <Layout>
                <div>
                    <Meta title={subtitle} postfix={title} description={subtitle} />
                    <Hero isHomePage title={subtitle} subtitle={title} />
                </div>
            </Layout>
        )
    }
}

export default IndexRoute

export const pageQuery = graphql`
  query IndexQuery {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
      }
    }
    kontentItemHomepage(system: {codename: {eq: "uvodni_stranka"}}) {
      elements {
        about_me {
          value
          links {
            link_id
            url_slug
            type
          }
          images {
            image_id
            description
            url
            fluid(maxWidth: 700) {
              ...KontentAssetFluid
            }
          }
          modular_content {
            ... on kontent_item_component_images_wrapper {
              id
              elements {
                description {
                  value
                }
                photos {
                  value {
                    fluid(maxWidth: 350) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                      aspectRatio
                      src
                      ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
            ... on kontent_item_component_text_and_image {
              id
              elements {
                text {
                  value
                }
                image {
                  value {
                    fluid(maxWidth: 350) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                        aspectRatio
                        src
                        ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
          }           
        }
      } 
    }  
 }
`
