import React from 'react'
import Waves from "../../assets/images/waves.inline.svg";
import HeroImage from "../../assets/images/hero-kartarka.png";


class Hero extends React.Component {

    render() {
        const heroTitle = this.props.title
        const heroSubtitle = this.props.subtitle

        return (
            <div>
                <div className="hero">
                    <div className="hero__header" style={{ height: "100vh" }}>
                        <div className="hero__content">
                            <div className="hero__name">{heroTitle}</div>
                            <h1>{heroSubtitle}</h1>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Hero
